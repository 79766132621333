import { Atom, SHARED_VALIDATIONS, helper } from ":mods";

export function createPersonalInfoInputs() {
  const FirstName = Atom.Form.createInput("", {
    required: true,
  });
  const LastName = Atom.Form.createInput("", {
    required: true,
  });
  const Gender = Atom.Form.createInput("", {
    required: true,
  });
  const DOB = Atom.Form.createInput("", {
    required: true,
  });
  const MobileNo = Atom.Form.createPhoneInput("", {
    required: true,
  });
  const CountryCode = Atom.Form.createInput("" as string, {
    required: true,
  });
  const WorkForMoc = Atom.Form.createInput("", {
    required: true,
  });
  const WhichCommision = Atom.Form.createSelect(undefined, {
    required: false,
  });
  const MocEmail = Atom.Form.createInput(undefined, {
    required: false,
    validators: (value: string) => {
      if (!value) {
        return undefined;
      }
      const err = SHARED_VALIDATIONS.email.validate(value);
      if (err?.length > 0) {
        return { [err]: true };
      }
      const is_moc = value?.trim()?.toLowerCase()?.endsWith("moc.gov.sa");
      if (!is_moc) {
        return { ["Must Be An MOC Email"]: true };
      }
      return undefined;
    },
  });

  const Actions = Atom.Form.createFormActions({
    FirstName,
    LastName,
    Gender,
    DOB,
    MobileNo,
    CountryCode,
    WorkForMoc,
    WhichCommision,
    MocEmail,
  });
  return {
    Actions,
    FirstName,
    LastName,
    Gender,
    DOB,
    MobileNo,
    CountryCode,
    WorkForMoc,
    WhichCommision,
    MocEmail,
  };
}
